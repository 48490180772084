import { useState, useEffect } from 'react';

// 自定義 hook 來檢查是否是手機版
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 定義媒體查詢
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    // 媒體查詢變化時的事件處理
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    // 初始設定
    setIsMobile(mediaQuery.matches);

    // 監聽媒體查詢變化
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // 清理事件監聽
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return isMobile;
}



export default useIsMobile;
